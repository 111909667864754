import * as React from "react"

import { Layout, SectionContainer } from "../components/_layout"
import { Indicadores } from "../components/IndicadoresModule"

const SegimientoDeIndicadores: React.FC = () => {
  return (
    <Layout
      withNav
      seoTitle="Indicadores"
      seoDescription="Seguimiento de indicadores"
    >
      <SectionContainer fullHeight sectionID="target-navigator">
        <Indicadores />
      </SectionContainer>
    </Layout>
  )
}

export default SegimientoDeIndicadores
